import rules from '@/utils/validate.js';
import { localDataUser, localDataPermission } from 'iebdc/utils/local-data';
import { localDataPermission as localDataPermissionGlobal } from 'src/utils/local-data';

const localDataPermissionData = localDataPermission ? localDataPermission.get() : localDataPermissionGlobal.get();
/**
 * 不动产基本信息
 */
//不动产权证号
const bdcqzh = {
  title: '不动产权证号',
  span: 24,
  type: 'chooseBdcqzh',
  key: 'bdcqzh',
  props: {
    mbanType: '证书模板',
    // disabled: localDataPermissionData.includes('IEBDC:SQ:CQZHY')
    // disabled: true
  },
  rule: [rules.required],
};

const tdsyqmj = {
  title: '土地使用权面积(㎡)',
  span: 12,
  type: 'input',
  key: 'tdsyqmj',
  rule: [rules.required],
};
//坐落
const zl = {
  title: '不动产坐落',
  span: 24,
  // type: localDataPermissionData.includes('IEBDC:SQ:CQZHY') ? 'zl' : 'input',
  type: 'zl',
  key: 'zl',
  props: {
    // placeholder: localDataPermissionData.includes('IEBDC:SQ:CQZHY') ? '' : '请填写坐落'
    placeholder: '请填写坐落',
  },
  rule: [rules.required],
};

//不动产单元号
const bdcdyh = {
  title: '不动产单元号',
  span: 12,
  type: 'input',
  key: 'bdcdyh',
  props: {
    placeholder: '请填写不动产单元号',
    // disabled: localDataPermissionData.includes('IEBDC:SQ:CQZHY')
    // disabled: true
  },
  rule: [rules.required, rules.estateUnitNo],
};
// 自然幢号
const zh = {
  title: '幢号',
  span: 12,
  type: 'input',
  key: 'zh',
  props: {
    placeholder: '请填写自然幢号',
  },
  rule: [rules.required],
};
// 房地产交易价格
const housetradeprice = {
  title: '房地产交易价格',
  span: 12,
  type: 'input',
  key: 'housetradeprice',
  props: {
    placeholder: '请填写房地产交易价格',
  },
  rule: [rules.required],
};
// 签约日期
const gfhtqdrq = {
  title: '签约日期',
  span: 12,
  type: 'date',
  key: 'gfhtqdrq',
  props: {
    placeholder: '请填写签约日期',
  },
  rule: [rules.required],
};
// 土地使用结束时间
const tdsyqjssj = {
  title: '土地使用结束时间',
  span: 12,
  type: 'date',
  key: 'tdsyqjssj',
  props: {
    placeholder: '请填写土地使用结束时间',
  },
  rule: [rules.required],
};
//房屋产权面积
const fwcqmj = {
  title: '房屋产权面积(㎡)',
  span: 12,
  type: 'input',
  key: 'fwcqmj',
  props: {
    placeholder: '请填写房屋产权面积(㎡)',
  },
  rule: [rules.required, rules.zhengnumber, rules.min(1)],
};

//不动产面积
const bdcmj = {
  title: '不动产面积(㎡)',
  span: 12,
  type: 'input',
  key: 'bdcmj',
  props: {
    placeholder: '请填写不动产面积(㎡)',
  },
  rule: [rules.required, rules.zhengnumber, rules.min(1)],
};

//房屋交易价格
const fwjyjg = {
  title: '房屋交易价格(万元)',
  span: 12,
  type: 'input',
  key: 'fwjyjg',
  props: {
    placeholder: '请填写房屋交易价格(万元)',
  },
  rule: [rules.required, rules.zhengFournumber, rules.min(0)],
};

//房屋交易价格不必填
const fwjyjgNotRequired = {
  title: '房屋交易价格(万元)',
  span: 12,
  type: 'input',
  key: 'fwjyjg',
  props: {
    placeholder: '请填写房屋交易价格(万元)',
  },
  rule: [rules.zhengFournumber],
};
//房屋交易价格必填
const fwjyjgRequired = {
  title: '房屋交易价格(万元)',
  span: 12,
  type: 'input',
  key: 'fwjyjg',
  props: {
    placeholder: '请填写房屋交易价格(万元)',
  },
  rule: [rules.required, rules.zhengFournumber],
};

// 取得价格必填
const qdjgRequired = {
  title: '取得价格(万元)',
  span: 12,
  type: 'input',
  key: 'fwjyjg',
  props: {
    placeholder: '请填写取得价格(万元)',
  },
  rule: [rules.required, rules.zhengFournumber],
};
// 取得价格非必填
const qdjgNotRequired = {
  title: '取得价格(万元)',
  span: 12,
  type: 'input',
  key: 'fwjyjg',
  props: {
    placeholder: '请填写取得价格(万元)',
  },
  rule: [rules.zhengFournumber],
};

//土地使用权人
const tdsyqr = {
  title: '土地使用权人',
  span: 12,
  type: 'input',
  key: 'tdsyqr',
  props: {
    placeholder: '请填写土地使用权人',
  },
  value: localDataUser.get() ? localDataUser.get().name : '',
  rule: [rules.required],
};

/**
 * 不动产扩展信息
 */
//申请证书模板
const sqzsbs = {
  title: '申请证书版式',
  span: 12,
  type: 'dicSelect',
  key: 'sqzsbs',
  props: {
    placeholder: '请选择申请证书版式',
    type: '申请证书版式',
  },
  value: 0,
  rule: [rules.required],
};

//是否分别持证
const sffbcz = {
  title: '是否分别持证',
  span: 12,
  type: 'radio',
  key: 'sffbcz',
  props: {
    placeholder: '请选择是否分别持证',
    options: [
      {
        value: 'true',
        label: '是',
      },
      {
        value: 'false',
        label: '否',
      },
    ],
  },
  value: 'false',
  rule: [rules.required],
};

//是否分多本证
const sfdbz = {
  title: '是否分多本证',
  span: 12,
  type: 'radio',
  key: 'sfdbz',
  props: {
    placeholder: '请选择是否分多本证',
    options: [
      {
        value: 'true',
        label: '是',
      },
      {
        value: 'false',
        label: '否',
      },
    ],
  },
  value: 'false',
  rule: [rules.required],
};

//登记申请原因
const sqdjyy = {
  title: '登记申请原因',
  span: 24,
  type: 'dicSelect',
  key: 'sqdjyy',
  props: {
    placeholder: '请选择/填写登记申请原因',
    type: '登记申请原因',
    allowCreate: true,
    filterable: true,
  },
  rule: [rules.required],
};

/**
 * 查封信息
 */
//原告人
const ygr = {
  title: '原告人',
  span: 12,
  type: 'input',
  props: {
    placeholder: '请填写原告人',
  },
  key: 'ygr',
};

//查封类型
const cflx = {
  title: '查封类型',
  span: 12,
  type: 'dicSelect',
  key: 'cflx',
  props: {
    placeholder: '请选择查封类型',
    type: '查封类型',
  },
  rule: [rules.required],
};

//查封起始时间
const cfqssj = {
  title: '查封起始时间',
  span: 12,
  type: 'date',
  key: 'cfqssj',
  props: {
    placeholder: '请选择查封起始时间',
  },
  ruleFunction: (Object) => {
    return [rules.required, rules.beforeTime(Object['cfjssj'])];
  },
};

//查封结束时间
const cfjssj = {
  title: '查封结束时间',
  span: 12,
  type: 'date',
  props: {
    placeholder: '请选择查封结束时间',
  },
  key: 'cfjssj',
  ruleFunction: (Object) => {
    return [rules.required, rules.afterTime(Object['cfqssj'])];
  },
};

//查封文号
const cfwh = {
  title: '查封文号',
  span: 12,
  type: 'input',
  key: 'cfwh',
  props: {
    placeholder: '请填写查封文号',
  },
  rule: [rules.required],
};

//查封文件
const cfwj = {
  title: '查封文件',
  span: 12,
  type: 'input',
  key: 'cfwj',
  props: {
    placeholder: '请填写查封文件',
  },
  rule: [rules.required],
};

//查封机关
const cfjg = {
  title: '查封机关',
  span: 12,
  type: 'input',
  key: 'cfjg',
  props: {
    placeholder: '请填写查封机关',
  },
  rule: [rules.required],
};

//查封范围
const cffw = {
  title: '查封范围',
  span: 12,
  type: 'input',
  key: 'cffw',
  props: {
    placeholder: '请填写查封范围',
  },
  rule: [rules.required],
};

//解封文号
const jfwh = {
  title: '解封文号',
  span: 12,
  type: 'input',
  key: 'jfwh',
  props: {
    placeholder: '请填写解封文号',
  },
  rule: [rules.required],
};

//解封文件
const jfwj = {
  title: '解封文件',
  span: 12,
  type: 'input',
  key: 'jfwj',
  props: {
    placeholder: '请填写解封文件',
  },
  rule: [rules.required],
};

//解封机关
const jfjg = {
  title: '解封机关',
  span: 12,
  type: 'input',
  key: 'jfjg',
  props: {
    placeholder: '请填写解封机关',
  },
  rule: [rules.required],
};

/**
 * 预告抵押信息
 */
//预售商品房抵押权预告 3
//预售商品房买卖预告 1
const ygdjzl1 = {
  title: '预售登记种类',
  span: 12,
  type: 'dicSelect',
  key: 'ygdjzl',
  props: {
    placeholder: '请选择预售登记种类',
    type: '预告登记种类',
    disabled: true,
  },
  value: '1',
  rule: [rules.required],
};

const ygdjzl3 = {
  title: '预售登记种类',
  span: 12,
  type: 'dicSelect',
  key: 'ygdjzl',
  props: {
    placeholder: '请选择预售登记种类',
    type: '预告登记种类',
    disabled: true,
  },
  value: '3',
  rule: [rules.required],
};

//被担保债权数额
const bdbzqse = {
  title: '被担保债权数额(万元)',
  span: 12,
  type: 'input',
  key: 'bdbzqse',
  props: {
    placeholder: '请填写被担保债权数额(万元)',
  },
  rule: [rules.required, rules.zhengFournumber],
  ifRender(object) {
    return (object && object.dyfs === '1') || !object.dyfs;
  },
};

//债务履行起始时间
const zwlxqssj = {
  title: '债务履行起始时间',
  span: 12,
  type: 'date',
  key: 'zwlxqssj',
  props: {
    placeholder: '请选择债务履行起始时间',
  },
  ruleFunction: (Object) => {
    return [rules.required, rules.beforeTime(Object['zwlxjssj'])];
  },
};

//债务履行终止时间
const zwlxjssj = {
  title: '债务履行终止时间',
  span: 12,
  type: 'date',
  key: 'zwlxjssj',
  props: {
    placeholder: '请选择债务履行终止时间',
  },
  ruleFunction: (Object) => {
    return [rules.required, rules.afterTime(Object['zwlxqssj'])];
  },
};

//抵押期限
const dyqx = {
  title: '抵押期限',
  span: 12,
  type: 'input',
  key: 'dyqx',
  props: {
    placeholder: '',
    disabled: true,
  },
  // 计算抵押期限
  computedFn(item) {
    if (item.zwlxjssj && item.zwlxqssj) {
      let dyqxDate = Math.trunc(Math.abs(item.zwlxjssj - item.zwlxqssj) / (1000 * 24 * 3600));
      let dyqxYears = Math.abs(item.zwlxjssj - item.zwlxqssj) / (1000 * 24 * 3600 * 365);
      if (dyqxYears < 1 || dyqxYears === 1) {
        //一年以内，展示天数
        return `${Math.trunc(dyqxDate)}天`;
      } else {
        //大于一年，展示年数+天数
        return `${Math.trunc(dyqxYears)}年${dyqxDate - Math.trunc(dyqxYears) * 365}天`;
      }
    }
  },
};
//抵押顺位
const dysw = {
  title: '抵押顺位',
  span: 24,
  type: 'input',
  key: 'dysw',
  props: {
    placeholder: '抵押顺位',
  },
  rule: [rules.required],
};
//不动产登记证明号
const bdcdjzmh = {
  title: '不动产登记证明号',
  span: 24,
  // type: localDataPermissionData.includes('IEBDC:SQ:CQZHY') ? 'chooseBdcqzh' : 'input',
  // type: 'chooseBdcqzh',
  type: 'input',
  key: 'bdcdjzmh',
  props: {
    placeholder: '新（年份）(登记机构所在市县) 不动产证明第 0000001～9999999 号',
    // mbanType: localDataPermissionData.includes('IEBDC:SQ:CQZHY') ? '抵押证明模板' : ''
    // mbanType: '抵押证明模板'
  },
  rule: [rules.required],
};

//不动产登记证明号（非必填）
const bdcdjzmh1 = {
  title: '不动产登记证明号',
  span: 24,
  type: 'input',
  key: 'bdcdjzmh',
  props: {
    placeholder: '新（年份）(登记机构所在市县) 不动产证明第 0000001～9999999 号',
  },
};

//抵押方式
const dyfs = {
  title: '抵押方式',
  span: 12,
  placeholder: '请选择抵押方式',
  type: 'dicSelect',
  key: 'dyfs',
  props: {
    placeholder: '请选择抵押方式',
    type: '抵押方式',
  },
  rule: [rules.required],
  input: (object) => {
    switch (object['dyfs']) {
      case '1':
        object['zgzqqdss'] = '';
        object['zgzqse'] = '';
        break;
      case '2':
        object['bdbzqse'] = '';
        break;
      default:
        break;
    }
  },
};

//最高债权数额(万元)
const zgzqse = {
  title: '最高债权数额(万元)',
  span: 12,
  type: 'input',
  key: 'zgzqse',
  props: {
    placeholder: '请填写最高债权数额(万元)',
  },
  rule: [rules.required, rules.zhengFournumber, rules.min(0)],
  ifRender(object) {
    return object && object.dyfs === '2';
  },
};

//最高债权确定事实
const zgzqqdss = {
  title: '最高债权确定事实',
  span: 24,
  type: 'input',
  key: 'zgzqqdss',
  props: {
    placeholder: '请填写最高债权确定事实',
  },
  rule: [rules.required],
  ifRender(object) {
    return object && object.dyfs === '2';
  },
};

//在建工程范围
const zjjzwdyfw = {
  title: '在建工程范围',
  span: 24,
  type: 'input',
  key: 'zjjzwdyfw',
  props: {
    placeholder: '在建工程范围',
  },
  rule: [rules.required],
};

//不动产评估价值
const bdcpgjz = {
  title: '不动产评估价值(万元)',
  span: 12,
  type: 'input',
  key: 'bdcpgjz',
  props: {
    placeholder: '不动产评估价值',
    //zslx: true,
    //zwdx: true
  },
  //rule: [rules.required, rules.zhengnumber, rules.min(0)]
  rule: [rules.required, rules.zhengFournumber],
};
//不动产评估价值（非必填）
const bdcpgjzNoRequired = {
  title: '不动产评估价值(万元)',
  span: 12,
  type: 'input',
  key: 'bdcpgjz',
  props: {
    placeholder: '不动产评估价值',
  },
  rule: [rules.zhengFournumber],
};

//担保范围
const dbfw = {
  title: '担保范围',
  span: 24,
  type: 'input',
  key: 'dbfw',
  props: {
    placeholder: '担保范围',
  },
  rule: [rules.required],
};

//不动产详情信息
//项目名称
const xmmc = {
  title: '项目名称',
  span: 12,
  props: {
    placeholder: '请填写项目名称',
  },
  type: 'input',
  key: 'xmmc',
  rule: [rules.required],
};

//房屋结构
const fwjg = {
  title: '房屋结构',
  span: 12,
  type: 'dicSelect',
  key: 'fwjg',
  props: {
    placeholder: '请选择房屋结构',
    type: '房屋结构',
  },
  rule: [rules.required],
};
// 建筑结构类型
const jzjg = {
  title: '建筑结构类型',
  span: 12,
  type: 'dicSelect',
  key: 'jzjg',
  props: {
    placeholder: '请选择建筑结构类型',
    type: '建筑结构类型',
  },
  rule: [rules.required],
};
//所在层
const szc = {
  title: '所在层',
  span: 12,
  type: 'input',
  key: 'szc',
  props: {
    placeholder: '请填写所在层',
  },
  rule: [rules.required, rules.number],
};

//总层数
const zcs = {
  title: '总层数',
  span: 12,
  type: 'input',
  key: 'zcs',
  props: {
    placeholder: '请填写总层数',
  },
  rule: [rules.required, rules.number],
};

//是否抵押
const sfdy = {
  title: '是否抵押',
  span: 12,
  type: 'radio',
  key: 'sfdy',
  props: {
    placeholder: '请选择是否抵押',
    options: [
      {
        value: '0',
        label: '否',
      },
      {
        value: '1',
        label: '是',
      },
    ],
  },
  rule: [rules.required],
};

//是否查封
const sfcf = {
  title: '是否查封',
  span: 12,
  type: 'radio',
  key: 'sfcf',
  props: {
    placeholder: '请选择是否查封',
    options: [
      {
        value: '0',
        label: '否',
      },
      {
        value: '1',
        label: '是',
      },
      {
        value: '2',
        label: '预查封',
      },
    ],
  },
  rule: [rules.required],
};

//是否异议
const sfyy = {
  title: '是否异议',
  span: 12,
  type: 'radio',
  key: 'sfyy',
  props: {
    placeholder: '请选择是否异议',
    options: [
      {
        value: '0',
        label: '否',
      },
      {
        value: '1',
        label: '是',
      },
    ],
  },
  rule: [rules.required],
};

//是否预告
const sfyg = {
  title: '是否预告',
  span: 12,
  type: 'radio',
  key: 'sfyg',
  props: {
    placeholder: '请选择是否预告',
    options: [
      {
        value: '0',
        label: '否',
      },
      {
        value: '1',
        label: '是',
      },
    ],
  },
  rule: [rules.required],
};

//室号部位
const shbw = {
  title: '室号部位',
  span: 12,
  type: 'input',
  key: 'shbw',
  props: {
    placeholder: '请填写室号部位',
  },
  rule: [rules.required],
};

//建筑面积
const jzmj = {
  title: '建筑面积',
  span: 12,
  type: 'input',
  key: 'jzmj',
  props: {
    placeholder: '请填写建筑面积',
  },
  rule: [rules.required, rules.zhengnumber],
};
//套内建筑面积
const tnjzmj = {
  title: '套内建筑面积',
  span: 12,
  type: 'input',
  key: 'tnjzmj',
  props: {
    placeholder: '请填写套内建筑面积',
  },
  rule: [rules.zhengnumber],
};

//分摊建筑面积
const ftjzmj = {
  title: '分摊建筑面积',
  span: 12,
  type: 'input',
  key: 'ftjzmj',
  props: {
    placeholder: '请填写分摊建筑面积',
  },
  rule: [rules.zhengnumber],
};

//房屋用途
const fwyt = {
  title: '房屋用途',
  span: 12,
  type: 'dicSelect',
  key: 'fwyt',
  props: {
    placeholder: '请选择房屋用途',
    type: '房屋用途',
  },
  rule: [],
};
/**
 * 出让人房屋信息
 */
//出让人房屋取得时间
const crrfwqdsj = {
  title: '出让人房屋取得时间',
  span: 12,
  type: 'date',
  key: 'crrfwqdsj',
  props: {
    placeholder: '请选择出让人房屋取得时间',
  },
  rule: [rules.required],
};

//房屋朝向
const fwcx = {
  title: '房屋朝向',
  span: 12,
  type: 'input',
  key: 'fwcx',
  props: {
    placeholder: '请选择房屋朝向',
    type: '房屋朝向',
  },
  rule: [rules.required],
};

//出让人取得方式
// const crrqdfs = {
//   title: '出让人取得方式',
//   span: 12,
//   type: 'dicSelect',
//   key: 'crrqdfs',
//   props: {
//     placeholder: '请选择出让人取得方式',
//     type: '房屋取得方式',
//   },
//   rule: [rules.required],
// };
// 房产交易方式
const crrqdfs = {
  title: '房产交易方式',
  span: 12,
  type: 'dicSelect',
  key: 'crrqdfs',
  props: {
    placeholder: '请选择房产交易方式',
    type: '房产交易方式',
  },
  rule: [rules.required],
};
//房屋性质
const fwxz = {
  title: '房屋性质',
  span: 12,
  type: 'dicSelect',
  key: 'fwxz',
  props: {
    placeholder: '请选择房屋性质',
    type: '房屋性质',
  },
  rule: [rules.required],
};
const tdyt = {
  title: '土地用途',
  span: 12,
  type: 'dicSelect',
  key: 'tdyt',
  props: {
    placeholder: '请选择土地用途',
    type: '土地用途',
  },
  // value: 0,
  rule: [rules.required],
};
//房产类型
const fwlx = {
  title: '房屋类型',
  span: 12,
  type: 'dicSelect',
  key: 'fwlx',
  props: {
    placeholder: '请选择房屋类型',
    type: '房屋类型',
  },
  rule: [rules.required],
};
// 房屋年代竣工日期
const jgsj = {
  title: '房屋年代竣工日期',
  span: 12,
  type: 'date',
  key: 'jgsj',
  props: {
    placeholder: '请选择房屋年代竣工日期',
  },
  rule: [rules.required],
};
// 房屋单元号
const dyh = {
  title: '房屋单元号',
  span: 12,
  type: 'input',
  key: 'dyh',
  props: {
    placeholder: '请选择房屋单元号',
    type: '房屋单元号',
  },
  rule: [rules.required],
};
//车库类型
const cklx = {
  title: '车库类型',
  span: 12,
  type: 'dicSelect',
  key: 'cklx',
  props: {
    placeholder: '请选择车库类型',
    type: '车库类型',
  },
  // rule: [rules.required]
};

//容积率
const rjl = {
  title: '容积率(%)',
  span: 12,
  type: 'input',
  key: 'rjl',
  props: {
    placeholder: '请填写容积率',
  },
  rule: [rules.min(0.01), rules.max(100)],
};

/**
 * 一窗转移参数
 */
//合同编号
const gfhtbh = {
  title: '购房合同编号',
  span: 12,
  type: 'input',
  key: 'gfhtbh',
  props: {
    placeholder: '请填写合同编号',
  },
  rule: [rules.required],
};

//合同编号(不必填)
const gfhtbh1 = {
  title: '购房合同编号',
  span: 12,
  type: 'input',
  key: 'gfhtbh',
  props: {
    placeholder: '请填写合同编号',
  },
};

//购房合同签订日期(不必填)
const gfhtrq = {
  title: '购房合同签订日期',
  span: 12,
  type: 'date',
  key: 'gfhtqdrq',
  props: {
    placeholder: '请选择购房合同签订日期',
  },
};

//纳税人识别号
const nsrsbh = {
  title: '纳税人识别号',
  span: 12,
  type: 'input',
  key: 'nsrsbh',
  props: {
    placeholder: '请填写纳税人识别号',
  },
  ruleFunction: (formData) => {
    if (formData.ywms === '1' || formData.ywms === '2' || !formData.ywms.length) {
      return [rules.noRequired];
    }
    if (formData.ywms === '3') {
      return [rules.required];
    }
  },
};

/**
 * 房屋转移信息
 */
//业务模式
const ywms = {
  title: '业务模式',
  span: 12,
  type: 'dicSelect',
  key: 'ywms',
  props: {
    placeholder: '请选择业务模式',
    type: '业务模式',
  },
  rule: [rules.required],
};
//房屋状态
const fwzt = {
  title: '房屋状态',
  span: 12,
  type: 'dicSelect',
  key: 'fwzt',
  props: {
    placeholder: '请选择房屋状态',
    type: '房屋状态',
  },
  rule: [rules.required],
};
//证书模式
const zsms = {
  title: '证书模式',
  span: 12,
  type: 'dicSelect',
  key: 'zsms',
  props: {
    placeholder: '请选择证书模式',
    type: '证书模式',
  },
  rule: [rules.required],
};
//缴费、领证方式
const jffs = {
  title: '缴费、领证方式',
  span: 12,
  type: 'dicSelect',
  key: 'jffs',
  props: {
    placeholder: '请选择缴费、领证方式',
    type: '缴费、领证方式',
  },
  rule: [rules.required],
};
//申请类型
const sqlx = {
  title: '申请类型',
  span: 12,
  type: 'dicSelect',
  key: 'sqlx',
  props: {
    placeholder: '请选择申请类型',
    type: '申请类型',
  },
  rule: [rules.required],
};

//登记事由
const djsy = {
  title: '登记事由',
  span: 24,
  type: 'dicSelect',
  key: 'djsy',
  props: {
    placeholder: '请选择登记事由',
    type: '登记事由',
  },
  rule: [rules.required],
};
//宗地代码
const zddm = {
  title: '宗地代码',
  span: 12,
  type: 'input',
  key: 'zddm',
  props: {
    placeholder: '请填写宗地代码',
    type: '宗地代码',
  },
  rule: [rules.required],
};
//权利性质
const qlxz = {
  title: '权利性质',
  span: 12,
  type: 'input',
  key: 'qlxz',
  props: {
    placeholder: '请填写权利性质',
    type: '权利性质',
  },
  rule: [rules.required],
};
//承包合同代码
const cbhtbm = {
  title: '承包合同代码',
  span: 12,
  type: 'input',
  key: 'cbhtbm',
  props: {
    placeholder: '请填写承包合同代码',
  },
};
//承包起始时间
const cbqssj = {
  title: '承包起始时间',
  span: 12,
  type: 'date',
  key: 'cbqssj',
  props: {
    placeholder: '请填写承包起始时间',
  },
  rule: [rules.required],
};
//权利性质
const cbjssj = {
  title: '承包结束时间',
  span: 12,
  type: 'date',
  key: 'cbjssj',
  props: {
    placeholder: '承包结束时间',
  },
  rule: [rules.required],
};
//承包期限
const cbqx = {
  title: '承包期限（年）',
  span: 12,
  type: 'input',
  key: 'cbqx',
  props: {
    placeholder: '承包期限',
  },
  rule: [rules.required],
};
//房屋首次,房屋变更,房屋转移,房屋注销,房屋更正,换证,补证
export const fwzyQlxxExForm = [
  {
    title: '其他申请信息',
    value: [sqzsbs, sffbcz, sfdbz, sqdjyy],
  },
];

//房屋转移
export const fwzyQlxxForm = [
  {
    value: [bdcqzh, zl, bdcdyh, fwcqmj, fwjyjg],
    // value: [bdcqzh,zl, bdcdyh, fwcqmj, fwjyjg, tdsyqr]
  },
];

export const fwzyycQlxxForm = [
  {
    // value: [bdcqzh, zl, bdcdyh, fwcqmj]
    value: [bdcqzh, zl, bdcdyh, fwcqmj, fwjyjgNotRequired],
    // value: [bdcqzh, zl, bdcdyh, fwcqmj, fwjyjg, tdsyqr]
  },
  // {
  //   value: [zh, fwjg, szc, zcs, sfdy, sfcf, sfyy, sfyg]
  // },
  // {
  //   value: [crrfwqdsj, fwcx, crrqdfs, fwjyjg, fwxz, fwlx, cklx, rjl]
  // }
];
export const fwzyycQlxxFormN200104 = [
  {
    // value: [bdcqzh, zl, bdcdyh, fwcqmj]
    value: [
      bdcqzh, //产权证号
      zl, //坐落
      bdcdyh, //不动产单元号
      fwcqmj, // 房屋产权面积
      fwjyjg, // 房屋交易价格
      fwlx, //房屋类型 对应文档housetype
      crrqdfs, //房产交易方式
      fwcx, //房屋朝向
      jzjg, //建筑结构
      fwyt, //房屋用途
      fwxz, //房屋性质
      jzmj, //建筑面积
      zddm, //宗地代码
      zh, //自然幢号
      dyh, //房屋单元号
      shbw, //室号
      szc, //实际层
      gfhtbh, // 购房合同编号
      gfhtqdrq, //签约日期
      tdsyqjssj, //土地使用结束时间
      jgsj, //房屋年代竣工日期
      zcs, //总层数
      tdyt, //总层数
    ],
    // value: [bdcqzh, zl, bdcdyh, fwcqmj, fwjyjg, tdsyqr]
  },
  // {
  //   value: [zh, fwjg, szc, zcs, sfdy, sfcf, sfyy, sfyg]
  // },
  // {
  //   value: [crrfwqdsj, fwcx, crrqdfs, fwjyjg, fwxz, fwlx, cklx, rjl]
  // }
];
// 房屋转移不动产弹框（企业）
// 房屋转移不动产弹框（企业）
export const qyfwzyycQlxxForm = [
  {
    value: [
      bdcqzh, //产权证号
      zl, //坐落
      bdcdyh, //不动产单元号
      fwcqmj, // 房屋产权面积
      fwjyjg, // 房屋交易价格
      fwlx, //房屋类型 对应文档housetype
      crrqdfs, //房产交易方式
      fwcx, //房屋朝向
      jzjg, //建筑结构
      fwyt, //房屋用途
      fwxz, //房屋性质
      jzmj, //建筑面积
      zddm, //宗地代码
      zh, //自然幢号
      dyh, //房屋单元号
      shbw, //室号
      szc, //实际层
      gfhtbh, // 购房合同编号
      gfhtqdrq, //签约日期
      tdsyqjssj, //土地使用结束时间
      jgsj, //房屋年代竣工日期
      zcs, //总层数
      tdyt, //土地用途
    ],
  },
];

// 房屋变更/更正/预告抵押注销不动产弹框（企业）
export const qyfwbgQlxxForm1 = [
  {
    value: [bdcqzh, zl, bdcdyh, fwcqmj],
  },
];
//  房屋变更不动产弹框（企业）
export const qyfwbgQlxxForm = [
  {
    value: [bdcqzh, zl, bdcdyh, fwcqmj],
  },
];
// 房屋注销及抵押预告不动产弹框（企业）
export const qyfwzxQlxxForm = [
  {
    value: [bdcqzh, zl, bdcdyh, fwcqmj],
  },
];
// 买卖抵押预告不动产弹框（企业）
export const qymmygdyQlxxForm = [
  {
    value: [bdcdjzmh, zl, bdcdyh, fwcqmj],
  },
];
// 买卖抵押预告注销预告信息表单（企业）
export const qymmygzxQlxxForm = [
  {
    value: [ygdjzl1, bdbzqse, fwcqmj, zwlxqssj, zwlxjssj, bdcpgjzNoRequired, bdcqzh, sqdjyy],
  },
];
export const fwzyycQlxxExForm = [
  {
    title: '房屋转移情况',
    // value: [ywms, fwzt, gfhtbh, nsrsbh, zsms, jffs, sqlx, sffbcz, sfdbz, sqzsbs, djsy, sqdjyy]
    value: [sqzsbs, sffbcz, sfdbz, sqdjyy],
  },
];
//房屋变更,房屋更正,换证,补证,一般抵押权首次,在建抵押权首次,抵押权注销
export const fwbgQlxxForm = [
  {
    // value: [bdcqzh, zl, bdcdyh, fwcqmj]
    value: [bdcqzh, zl, bdcdyh],
    // value: [bdcqzh, zl, bdcdyh, fwcqmj, tdsyqr]
  },
];
export const mmygdjdyQlxxForm = [
  {
    title: '不动产基本信息',
    showSync: true,
    value: [zl, bdcdyh, fwcqmj, gfhtbh1],
  },
];

//抵押权注销(ddcome@Right)
export const dyqzxForm = [
  {
    // value: [bdcqzh, zl, bdcdyh, fwcqmj]
    value: [bdcdjzmh, zl, bdcdyh, fwcqmj],
    // value: [bdcqzh, zl, bdcdyh, fwcqmj, tdsyqr]
  },
];

//一般抵押权首次（ddcome@Right）
export const dyqscForm = [
  {
    value: [bdcqzh, zl, bdcdyh, fwcqmj],
  },
];

//房屋首次
export const fwscQlxxForm = [
  {
    value: [zl, bdcdyh],
    // value: [zl, bdcdyh, fwcqmj, tdsyqr]
  },
];

//房屋注销
export const fwzxQlxxForm = [
  {
    value: [bdcqzh, zl, bdcdyh],
    // value: [bdcqzh, zl, bdcdyh, tdsyqr]
  },
];
//房屋查封
export const fwcfQlxxForm = [
  {
    value: [bdcqzh, zl, bdcdyh, fwcqmj],
  },
];
//土地抵押权首次登记，土地抵押权转移登记
export const tddyqscQlxxForm = [
  {
    value: [dyfs, bdbzqse, zgzqse, zgzqqdss, bdcpgjz, zwlxqssj, zwlxjssj, sqdjyy],
  },
];

//国有建设用地使用权首次登记 其他申请信息
export const GyjsydSyqScQtQlxxForm = [
  {
    title: '其他申请信息',
    value: [sqzsbs, sffbcz, sfdbz, sqdjyy],
  },
];

//土地抵押权注销
export const tddyqzxQlxxForm = [
  {
    value: [bdcdjzmh, dyfs, bdbzqse, zgzqse, zgzqqdss, bdcpgjz, zwlxqssj, zwlxjssj, sqdjyy],
  },
];
//房屋查封
export const fwcfQlxxExForm = [
  {
    title: '查封情况',
    value: [ygr, cflx, cfqssj, cfjssj, cfwh, cfwj, cfjg, cffw, sqdjyy],
  },
];
//房屋解封
export const fwjfQlxxExForm = [
  {
    title: '解封情况',
    value: [ygr, cflx, cfqssj, cfjssj, cfwh, cfwj, cfjg, cffw, jfjg, jfwh, jfwj, sqdjyy],
  },
];
//预告买卖,预告抵押,预告买卖注销,预告抵押注销
export const mmygQlxxForm = [
  {
    value: [zl, bdcdyh, fwcqmj, bdcdjzmh1],
    // value: [zl, bdcdyh, fwcqmj, fwjyjg, tdsyqr]
  },
];
//预告抵押
export const ygdyQlxxForm = [
  {
    value: [zl, bdcdyh, bdcdjzmh, fwcqmj],
    // value: [zl, bdcdyh, fwcqmj, fwjyjg, tdsyqr]
  },
];
//预告买卖注销
export const mmygzxQlxxForm = [
  {
    value: [zl, bdcdyh, gfhtbh, bdcdjzmh, fwcqmj],
    // value: [zl, bdcdyh, fwcqmj, fwjyjg, tdsyqr]
  },
];
//预告抵押注销
export const mmygdyzxQlxxForm = [
  {
    value: [zl, bdcdyh, fwcqmj, bdcdjzmh],
    // value: [zl, bdcdyh, fwcqmj, fwjyjg, tdsyqr]
  },
];
//预告买卖,预告买卖注销
export const mmygQlxxExForm = [
  {
    title: '预告信息(单位:平方米、万元)',
    value: [ygdjzl1, sqdjyy],
  },
];

//买卖预告登记   叶
export const MmygdjQlxxExForm = [
  {
    title: '预告信息(单位:平方米、万元)',
    value: [sqdjyy],
  },
];

//预告注销
export const mmygzxQlxxExForm = [
  {
    title: '预告注销情况(单位:平方米、万元)',
    value: [sqdjyy],
  },
];

//预售商品房抵押预告登记 抵押信息 （个人） 叶
export const ysspfdyygQlxxExForm = [
  {
    title: '预告抵押情况(单位:平方米、万元)',
    value: [ygdjzl3, bdbzqse, zwlxqssj, zwlxjssj, bdcpgjzNoRequired, sqdjyy],
  },
];

//预售商品房抵押预告登记 抵押信息 （企业） 叶
export const ysspfdyygQyQlxxExForm = [
  {
    value: [ygdjzl3, bdbzqse, fwcqmj, zwlxqssj, zwlxjssj, bdcpgjzNoRequired, bdcdyh, sqdjyy],
  },
];

// let qydyygQlxxExForm;
// if (localDataUser.get().userType === 'jg') {
//     qydyygQlxxExForm = [
//       {
//
//         value: [ygdjzl3, bdbzqse, fwcqmj, zwlxqssj, zwlxjssj, bdcpgjzNoRequired, bdcdyh, sqdjyy]
//       }
//     ];
// } else {
//     qydyygQlxxExForm = [
//       {
//         title: '预告抵押情况(单位:平方米、万元)',
//         value: [ygdjzl3, bdbzqse, zwlxqssj, zwlxjssj, bdcpgjzNoRequired, sqdjyy]
//       }
//     ];
// }
//
// //export const dyygQlxxExForm = qydyygQlxxExForm;

//买卖预告登记+抵押预告登记 抵押预告登记  叶
export const DyygdjQlxxExForm = [
  {
    title: '抵押权预告信息(单位:平方米、万元)',
    value: [zwlxqssj, zwlxjssj, bdcpgjz, bdbzqse, sqdjyy],
  },
];
//买卖预告登记+抵押预告登记 不动产信息
export const dyygdjQlxxExMhsExForm = [
  {
    title: '不动产信息',
    value: [zl, bdcdyh, fwcqmj],
  },
];

//预告抵押注销, 一般抵押注销, 在建抵押注销
export const dyygzxQlxxExForm = [
  {
    title: '抵押情况(单位:平方米、万元)',
    value: [bdcdjzmh, dyfs, bdbzqse, zgzqse, zgzqqdss, zwlxqssj, zwlxjssj, sqdjyy],
  },
];
//抵押权注销（ddcome@Right）
export const dyqzxExForm = [
  {
    title: '抵押情况(单位:平方米、万元)',
    value: [dyfs, bdcpgjz, bdbzqse, zgzqse, zgzqqdss, zwlxqssj, zwlxjssj, sqdjyy],
  },
];
//预告抵押注销
export const ygdyzxQlxxExForm = [
  {
    title: '抵押注销情况(单位:平方米、万元)',
    // value: [bdcdjzmh, ygdjzl3, bdbzqse, zgzqse, zgzqqdss, zwlxqssj, zwlxjssj, sqdjyy]
    value: [dyfs, bdbzqse, zgzqse, zgzqqdss, zwlxqssj, zwlxjssj, sqdjyy],
  },
];
//一般抵押权首次（ddcome@Right）
export const ybdyqscQlxxExForm = [
  {
    title: '抵押情况(单位:平方米、万元)',
    value: [dyfs, bdbzqse, zgzqse, zgzqqdss, bdcpgjzNoRequired, zwlxqssj, zwlxjssj, sqdjyy],
  },
];
//一般抵押权首次抵押情况
export const ybdyscdyqkForm = [
  {
    title: '抵押情况(单位:平方米、万元)',
    value: [dyfs, bdbzqse, zgzqse, zgzqqdss, bdcpgjzNoRequired, dyqx, zwlxqssj, zwlxjssj, sqdjyy],
  },
];
//抵押信息 *
export const DyxxQlxxExForm = [
  {
    title: '抵押信息(单位:平方米、万元)',
    value: [dyfs, bdcpgjz, zwlxqssj, zwlxjssj, sqdjyy],
  },
];
//在建抵押权首次
export const zjdyqscQlxxExForm = [
  {
    title: '抵押情况(单位:平方米、万元)',
    value: [zjjzwdyfw, dyfs, bdbzqse, zgzqse, zgzqqdss, zwlxqssj, zwlxjssj, sqdjyy],
  },
];

//房屋首次登记
//所有不动产表格详情弹框都使用的此配置
export const fwscQlxxDialogForm = [
  {
    value: [zl, bdcdyh],
    // value: [zl, bdcdyh, fwcqmj, tdsyqr]
  },
  {
    value: [xmmc, zh, shbw, jzmj, tnjzmj, ftjzmj, fwyt],
  },
];

//除抵押首次和抵押注销/预告之外，所有不动产表格详情弹框都使用的此配置
export const qlxxDialogForm = [
  {
    value: [bdcqzh, zl, bdcdyh, fwcqmj],
    // value: [bdcqzh, zl, bdcdyh, fwcqmj, fwjyjg, tdsyqr]
  },
  {
    value: [xmmc, zh, shbw, jzmj, tnjzmj, ftjzmj, fwyt],
  },
];

//所有权转移登记+抵押权首次登记 所有权转移登记
export const syqzyQlxxForm = [
  {
    value: [bdcqzh, zl, bdcdyh, fwcqmj],
    // value: [bdcqzh, zl, bdcdyh, fwcqmj, fwjyjg, tdsyqr]
  },
];

//房屋抵押首次;抵押注销;
export const otherqlxxDialogForm = [
  {
    value: [bdcqzh, zl, bdcdyh, fwcqmj],
  },
  {
    value: [xmmc, zh, shbw, jzmj, tnjzmj, ftjzmj, fwyt],
  },
];

//买卖预告登记+抵押预告登记不动产信息
export const mmygdjdyDialogForm = [
  {
    value: [zl, bdcdyh, fwcqmj, gfhtbh1],
  },
  {
    value: [xmmc, zh, shbw, jzmj, tnjzmj, ftjzmj, fwyt],
  },
];
//抵押预告登记不动产信息
export const djygdjdyDialogForm = [
  {
    value: [zl, bdcdyh, fwcqmj],
  },
  {
    value: [xmmc, zh, shbw, jzmj, tnjzmj, ftjzmj, fwyt],
  },
];
//预告抵押注销不动产表格详情弹框
export const ygqlxxDialogForm = [
  {
    value: [zl, bdcdyh, fwcqmj, tdsyqr, fwjyjgNotRequired],
  },
  {
    value: [xmmc, zh, shbw, jzmj, tnjzmj, ftjzmj, fwyt],
  },
];
//买卖预告登记不动产表格详情弹框
export const mmygqlxxDialogForm = [
  {
    value: [zl, bdcdyh, fwcqmj, gfhtbh1, fwjyjgNotRequired],
  },
  // {
  //   value: [xmmc, zh, shbw, jzmj, tnjzmj, ftjzmj, fwyt]
  // }
];
//买卖预告登记(企业)不动产表格详情弹框
export const qymmygqlxxDialogForm = [
  {
    value: [
      zl,
      bdcdyh,
      fwcqmj,
      gfhtbh,
      gfhtrq,
      fwyt,
      fwxz,
      zh,
      szc,
      dyh,
      jzjg,
      tnjzmj,
      shbw,
      crrqdfs,
      fwjyjgNotRequired,
    ],
  },
];
//二手房买卖预告登记不动产表格详情弹框
export const esfmmygqlxxDialogForm = [
  {
    value: [zl, bdcdyh, fwcqmj, fwjyjgNotRequired, gfhtbh1, gfhtrq],
  },
  // {
  //   value: [xmmc, zh, shbw, jzmj, tnjzmj, ftjzmj, fwyt]
  // }
];
//预告买卖注销/预告抵押登记的不动产表格弹框
export const mmygqlxxDialogFormN = [
  {
    value: [zl, bdcdyh, tdsyqr, qdjgNotRequired],
  },
  {
    value: [xmmc, zh, shbw, jzmj, tnjzmj, ftjzmj, fwyt],
  },
];
//预告买卖注销/表格弹框
export const mmygzxdjQlxxExForm = [
  {
    value: [bdcqzh, zl, bdcdyh, fwcqmj, fwjyjgNotRequired, gfhtbh],
  },
];
//土地抵押权首次；土地抵押权注销
export const tddyqlxxDialogForm = [
  {
    // value: [zl, bdcdyh, tdsyqmj, jzmj]
    value: [zl, bdcdyh, tdsyqmj],
  },
];

//国有建设用地使用权 首次登记
export const GyjsydSyqScQlxxForm = [
  {
    title: '不动产情况',
    // value: [zl, bdcdyh, tdsyqmj, jzmj]
    value: [zl, bdcdyh, tdsyqmj],
  },
];

//土地抵押权转移
export const tddyzyqlxxDialogForm = [
  {
    // value: [zl, bdcdyh, tdsyqmj, jzmj]
    value: [zl, bdcdyh, tdsyqmj, bdcdjzmh],
  },
];

//房屋所有权转移登记+抵押权变更登记（同行带押过户）
export const dyghDyxxExForm = [
  {
    title: '抵押情况(单位:平方米、万元)',
    value: [dyfs, bdbzqse, zgzqse, zgzqqdss, bdcpgjzNoRequired, zwlxqssj, zwlxjssj, dyqx, dysw, sqdjyy],
  },
];

//土地经营权;
export const tdjyqqlxxDialogForm = [
  {
    value: [qlxz, bdcdyh, cbhtbm, cbqx, cbqssj, cbjssj, zl],
  },
];

// 宅基地使用权及房屋所有权首次登记 tdyt zdmj, syqx, syqqssj, syqjssj
// export const zjdsyqjfwsyqscdj = [
//   {
//     bdcdyh, zl, fwyt, jzmj, fwjg, fwlx, tdsyqmj, tdsyqr
//   }
// ]

/**
 *  宅基地使用权(土地)
 */
// 土地用途
// const tdyt = {
//   title: '土地用途',
//   span: 12,
//   type: 'dicSelect',
//   key: 'tdyt',
//   props: {
//     placeholder: '请选择土地用途',
//     type: '土地用途',
//   },
//   rule: [rules.required],
// };

// 宗地面积
const zdmj = {
  title: '宗地面积',
  span: 12,
  type: 'input',
  key: 'zdmj',
  props: {
    placeholder: '请输入宗地面积',
  },
  rule: [rules.required, rules.zhengnumber],
};

// 土地使用权起始时间
const syqqssj = {
  title: '土地使用权起始时间',
  span: 12,
  type: 'date',
  key: 'syqqssj',
  props: {
    placeholder: '请选择土地使用权起始时间',
  },
  ruleFunction: (Object) => {
    return [rules.required, rules.beforeTime(Object['syqjssj'])];
  },
};

// 土地使用权结束时间
const syqjssj = {
  title: '土地使用权结束时间',
  span: 12,
  type: 'date',
  key: 'syqjssj',
  props: {
    placeholder: '请选择土地使用权结束时间',
  },
  ruleFunction: (Object) => {
    return [rules.required, rules.afterTime(Object['syqqssj'])];
  },
};

// 宅基地使用权首次登记（土地）
export const zjdsyqscdj = [
  {
    value: [zl, bdcdyh, tdsyqr],
  },
];

// 宅基地使用权（土地）bdcdyh, zl, tdyt, zdmj, tdsyqmj, /syqx, syqqssj, syqjssj, tdsyqr
export const zjdsyqzydjQlxxExForm = [
  {
    //value: [bdcqzh, zl, bdcdyh, tdyt, zdmj, tdsyqmj, syqqssj, syqjssj, tdsyqr],
    value: [bdcqzh, zl, bdcdyh, zdmj, tdsyqmj, syqqssj, syqjssj, tdsyqr],
  },
];

//证书换证登记 证书补证登记 所有权转移登记 *抵押  * 房屋所有权变更登记+房屋所有权转移登记  * 叶林谦
export const ZshzdjQlxxForm = [
  {
    //value: [bdcqzh, zl, bdcdyh, tdyt, zdmj, tdsyqmj, syqqssj, syqjssj, tdsyqr],
    value: [bdcqzh, zl, bdcdyh, fwcqmj],
  },
];

//国有建设用地使用权及房屋所有权 首次登记 不动产情况 叶林谦
export const GyjsFwsyqScdjQlxxForm = [
  {
    //value: [bdcqzh, zl, bdcdyh, tdyt, zdmj, tdsyqmj, syqqssj, syqjssj, tdsyqr],
    value: [zl, bdcdyh, fwcqmj, jzmj, tdsyqr],
  },
];

//国有建设用地使用权及房屋所有权 首次登记 不动产情况 （企业）叶林谦
export const GyjsFwsyqScdjQyQlxxForm = [
  {
    //value: [bdcqzh, zl, bdcdyh, tdyt, zdmj, tdsyqmj, syqqssj, syqjssj, tdsyqr],
    value: [zl, bdcdyh, fwcqmj],
  },
];

// 土地经营权
export const tdjyqQlxxForm = [
  {
    value: [qlxz, bdcdyh, cbhtbm, cbqx, cbqssj, cbjssj, zl],
  },
];
// 林地信息相关配置
// 林地使用权-首次登记
//申请证书模板
const ldqllx = {
  title: '权利类型',
  span: 12,
  type: 'dicSelect',
  key: 'ldqllx',
  props: {
    placeholder: '请选择林地权利类型',
    type: '林地权利类型',
  },
  value: 0,
  rule: [rules.required],
};
const ldfbf = {
  title: '发包方',
  span: 12,
  type: 'input',
  key: 'fbf',
  props: {
    placeholder: '请填写发包方',
  },
  rule: [rules.required],
};
const ldqlr = {
  title: '林地权利人',
  span: 12,
  type: 'input',
  key: 'qlr',
  props: {
    placeholder: '请填写发包方',
  },
  rule: [rules.required],
};
const ldzl = {
  title: '坐落',
  span: 24,
  type: 'input',
  key: 'zl',
  props: {
    placeholder: '请填写坐落',
  },
  rule: [rules.required],
};
const ldqlxz = {
  title: '权利性质',
  span: 12,
  type: 'dicSelect',
  key: 'ldqlxz',
  props: {
    placeholder: '请填写林地权利性质',
    type: '林地权利性质',
  },
  value: 0,
  rule: [rules.required],
};
const ldsyqxz = {
  title: '林地所有权性质',
  span: 12,
  type: 'dicSelect',
  key: 'ldsyqxz',
  props: {
    placeholder: '请选择林地所有权性质',
    type: '林地所有权性质',
  },
  value: 0,
  rule: [rules.required],
};
const sz = {
  title: '主要树种',
  span: 24,
  type: 'input',
  key: 'sz',
  props: {
    placeholder: '请填写树种',
  },
  rule: [rules.required],
};
const xdfm = {
  title: '小地方名',
  span: 12,
  type: 'input',
  key: 'xdfm',
  props: {
    placeholder: '请填写小地方名',
  },
  rule: [rules.required],
};
const sljlmsyqr = {
  title: '森林、林木所有权人',
  span: 12,
  type: 'input',
  key: 'sljlmsyqr',
  props: {
    placeholder: '请填写森林、林木所有权人',
  },
};
const sljlmqlr = {
  title: '森林、林木权利人',
  span: 12,
  type: 'input',
  key: 'sljlmqlr',
  props: {
    placeholder: '请填写森林、林木权利人',
  },
};
const ldzs = {
  title: '株数',
  span: 12,
  type: 'input',
  key: 'ldzs',
  props: {
    placeholder: '请填写株数',
  },
};
const ldlz = {
  title: '林种',
  span: 12,
  type: 'input',
  key: 'ldlz',
  props: {
    placeholder: '请填写林种',
  },
  rule: [rules.required],
};
const ldlb = {
  title: '林班',
  span: 12,
  type: 'input',
  key: 'ldlb',
  props: {
    placeholder: '请填写林班',
  },
  rule: [rules.required],
};
const smjlmqy = {
  title: '起源',
  span: 12,
  type: 'dicSelect',
  key: 'smjlmqy',
  props: {
    placeholder: '请选择林地起源',
    type: '林地起源',
  },
  value: 0,
  rule: [rules.required],
};
const zlnd = {
  title: '造林年度',
  span: 12,
  type: 'input',
  key: 'zlnd',
  value: 0,
  props: {
    placeholder: '请填写造林年度',
  },
  rule: [rules.required],
};
const ldsyqcbmj = {
  title: '使用权(承包)面积',
  span: 12,
  type: 'input',
  key: 'ldsyqcbmj',
  props: {
    placeholder: '请填写使用权(承包)面积',
  },
  rule: [rules.required],
};

const ldcbsyqx = {
  title: '林地(承包)使用期限',
  span: 12,
  type: 'input',
  key: 'ldcbsyqx',
  props: {
    placeholder: '请填写林地(承包)使用期限',
  },
};
// 林地使用起始时间
const ldsyqssj = {
  title: '林地使用起始时间',
  span: 12,
  type: 'date',
  key: 'ldsyqssj',
  props: {
    placeholder: '请填写林地使用起始时间',
  },
};
// 林地使用结束时间
const ldsyjssj = {
  title: '林地使用结束时间',
  span: 12,
  type: 'date',
  key: 'ldsyjssj',
  props: {
    placeholder: '请填写林地使用结束时间',
  },
};
const ldfwE = {
  title: '林地四至-东',
  span: 24,
  type: 'input',
  key: 'ldfwE',
  props: {
    placeholder: '请填写林地四至-东',
  },
  rule: [rules.required],
};

const ldfwS = {
  title: '林地四至-南',
  span: 24,
  type: 'input',
  key: 'ldfwS',
  props: {
    placeholder: '请填写林地四至-南',
  },
  rule: [rules.required],
};

const ldfwW = {
  title: '林地四至-西',
  span: 24,
  type: 'input',
  key: 'ldfwW',
  props: {
    placeholder: '请填写林地四至-西',
  },
  rule: [rules.required],
};

const ldfwN = {
  title: '林地四至-北',
  span: 24,
  type: 'input',
  key: 'ldfwN',
  props: {
    placeholder: '请填写林地四至-北',
  },
  rule: [rules.required],
};

export const ldsyqscdjQlxxForm = [
  {
    value: [
      bdcdyh,
      ldqllx,
      ldzl,
      ldqlxz,
      ldsyqxz,
      ldqlr,
      ldsyqcbmj,
      ldsyqssj,
      ldsyjssj,
      ldcbsyqx,
      ldfwE,
      ldfwS,
      ldfwW,
      ldfwN,
    ],
  },
];
export const ldsyqjslldscdjQlxxForm = [
  {
    title: '森林、林木信息',
    value: [sz, xdfm, sljlmsyqr, sljlmqlr, ldzs, ldlz, ldlb, smjlmqy, zlnd],
  },
  {
    title: '林地信息',
    value: [
      bdcdyh,
      ldqllx,
      ldzl,
      ldqlxz,
      ldsyqxz,
      ldqlr,
      ldsyqcbmj,
      ldsyqssj,
      ldsyjssj,
      ldcbsyqx,
      ldfwE,
      ldfwS,
      ldfwW,
      ldfwN,
    ],
  },
];
